import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { BootstrapVue3 } from "bootstrap-vue-3";
import VueGtag from "vue-gtag-next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "@/assets/css/layout.scss";
import "@/assets/css/common.scss";

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.use(VueGtag, {
  property: { id: "G-112J6MR0Z7" },
});
app.mount("#app");
